import gql from 'graphql-tag'

const fragments = {
    onboardingDashboard: gql`
      fragment onboardingDashboard on OnboardingDashboard {
        accountId
        datePracticeAdded
        id
        marketingMaterialApproved
        patientPlansComplete
        planId
        account{
            name
            code
            status
        }
        plan {
            name
            status
            type
        }
        subscription{
            createdAt
            acceptDate
            startDate
            endDate
            fee
            status
        }
        practiceActive
        practiceClosed
        practiceFeeAnalysisComplete
        practiceLive
        practiceName
        practiceProgramApproved
        processingComplete
        registrationComplete
        serviceAgreementComplete
        serviceAgreementPending
        softwareSetupGuideSent
        offices {
          address1
          address2
          city
          zip
          state
          phone
          email
        }
      }
    `
}

export const GET_ONBOARDING_DASHBOARD_TABLE = gql`
query OnboardingDashboardTable($first: Int, $after: String) {
  OnboardingDashboardEvents(first: $first, after: $after) {
        edges {
          cursor
          node {
            id
            accountId
            account{
              id
              code
              name
            }
            datePracticeAdded
            marketingMaterialApproved
            patientPlansComplete
            planId
            plan {
                name
                status
            }
            account{
              code
            }
            practiceActive
            practiceClosed
            practiceFeeAnalysisComplete
            practiceLive
            practiceName
            practiceProgramApproved
            processingComplete
            registrationComplete
            serviceAgreementComplete
            serviceAgreementPending
            softwareSetupGuideSent
            subscriptionId
            onboardType
            subscription{
                acceptDate
                createdAt
                startDate
                endDate
                fee
            }

          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
}
`

export const GET_ONBOARDING_DASHBOARD_TABLE_SEARCH_NAME = gql`
query OnbordingDashbordTableSearchByName($search: String!, $orderByCol: String!, $order: String!, $first: Int, $after: String) {
  OnbordingDashbordTableSearchByName(search: $search, orderByCol: $orderByCol, order: $order, first: $first, after: $after){
    edges {
          cursor
          node {
            id
            accountId
            account{
              id
              code
              name
            }
            datePracticeAdded
            marketingMaterialApproved
            patientPlansComplete
            planId
            plan {
                name
            }
            practiceActive
            practiceClosed
            practiceFeeAnalysisComplete
            practiceLive
            practiceName
            practiceProgramApproved
            processingComplete
            registrationComplete
            serviceAgreementComplete
            serviceAgreementPending
            softwareSetupGuideSent
            subscriptionId
            onboardType
            subscription{
                acceptDate
                createdAt
            }


          }
        
        }
        pageInfo {
          endCursor
          hasNextPage
        }
  }
}
`
// export const GET_ONBOARDING_DASHBOARD_TABLE_SEARCH_NAME = gql`
// query OnbordingDashbordTableSearchByName($search: String!,$first: Int, $after: String) {
//   OnbordingDashbordTableSearchByName(search: $search, first: $first, after: $after){
//     edges {
//           cursor
//           node {
//             id
//             accountId
//             datePracticeAdded
//             marketingMaterialApproved
//             patientPlansComplete
//             planId
//             plan {
//                 name
//             }
//             practiceActive
//             practiceClosed
//             practiceFeeAnalysisComplete
//             practiceLive
//             practiceName
//             practiceProgramApproved
//             processingComplete
//             registrationComplete
//             serviceAgreementComplete
//             serviceAgreementPending
//             softwareSetupGuideSent
//             subscriptionId
//             onboardType
//             subscription{
//                 acceptDate
//                 createdAt
//             }


//           }
         
//         }
//         pageInfo {
//           endCursor
//           hasNextPage
//         }
//   }
// }
// `


export const GET_ONBOARDING_DASHBOARD = gql`
query getOnboardingDashboard {
    getOnboardingDashboard {
        ...onboardingDashboard
    }
}
${fragments.onboardingDashboard}
`
export const UPSERT_ONBOARDING_DASHBOARD = gql`
mutation upsertOnboardingDashboard (
    $datePracticeAdded: DateTime, 
    $id: ID, 
    $marketingMaterialApproved: DateTime, 
    $patientPlansComplete: DateTime, 
    $practiceActive: DateTime, 
    $practiceClosed: DateTime, 
    $practiceFeeAnalysisComplete: DateTime, 
    $practiceLive: DateTime, 
    $practiceName: String, 
    $practiceProgramApproved: DateTime, 
    $processingComplete: DateTime, 
    $registrationComplete: DateTime, 
    $serviceAgreementComplete: DateTime, 
    $serviceAgreementPending: DateTime, 
    $softwareSetupGuideSent: DateTime) {
    upsertOnboardingDashboard (
        datePracticeAdded: $datePracticeAdded, 
        id: $id, 
        marketingMaterialApproved: $marketingMaterialApproved, 
        patientPlansComplete: $patientPlansComplete, 
        practiceActive: $practiceActive, 
        practiceClosed: $practiceClosed, 
        practiceFeeAnalysisComplete: $practiceFeeAnalysisComplete, 
        practiceLive: $practiceLive, 
        practiceName: $practiceName, 
        practiceProgramApproved: $practiceProgramApproved, 
        processingComplete: $processingComplete, 
        registrationComplete: $registrationComplete, 
        serviceAgreementComplete: $serviceAgreementComplete, 
        serviceAgreementPending: $serviceAgreementPending, 
        softwareSetupGuideSent: $softwareSetupGuideSent) {
        datePracticeAdded
        id
        marketingMaterialApproved
        patientPlansComplete
        practiceActive
        practiceClosed
        practiceFeeAnalysisComplete
        practiceLive
        practiceName
        practiceProgramApproved
        processingComplete
        registrationComplete
        serviceAgreementComplete
        serviceAgreementPending
        softwareSetupGuideSent
    }
}
`

export const CLOSE_ACCOUNT = gql`
mutation closeAccount ($id: ID!) {
  closeAccount(id: $id) {
    id,
    name,
    status
  }
}
`

export const REVERT_ACCOUNT = gql`
mutation revertAccount ($id: ID!) {
  revertAccount(id: $id) {
    id,
    name,
    status
  }
}
`

export const DELETE_ACCOUNT = gql`
mutation deleteAccount ($id: ID!) {
  deleteAccount(id: $id) {
    id,
    name,
    status
  }
}
`