import React from 'react'
import ReactDOM from 'react-dom'

import 'normalize.css'
import 'typeface-open-sans'
import './index.css'
import { initializeIconLibrary } from 'utility/icon-library'
import 'react-quill/dist/quill.snow.css';

import App from './App'

initializeIconLibrary()

//@ts-ignore
// ReactDOM.createRoot(document.getElementById('root')).render(<App />)

ReactDOM.render(<App />, document.getElementById('root'))
