import gql from 'graphql-tag'

const fragments = {
  // also refactor planGridFields
  userFields: gql`
    fragment userFields on User {
      id
      firstName
      lastName
      google {
        email
        verified
        role
      }
    }
  `
}

export const CREATE_USER = gql`
  mutation CreateUser($email: String!, $firstName: String!, $lastName: String!, $role: UserRole) {
    createUser(email: $email, firstName: $firstName, lastName: $lastName, role: $role) {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $email: String!, $firstName: String!, $lastName: String!, $role: UserRole) {
    updateUser(id: $id, email: $email, firstName: $firstName, lastName: $lastName, role: $role) {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const GET_USERS = gql`
  query GetUsers {
    users {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const USER_SHEET_RESEND_INVITE = gql`
  mutation UserSheetResendInvite($id: ID!) {
    resendUserInvite(id: $id) {
      id
    }
  }
`
