import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_ONBOARDING_PRACTICE, SUBMIT_ONBOARDING_PRACTICE, UPDATE_ONBOARDING_PRACTICE } from 'graphql/_onboarding'
import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Text, Heading, Pill, toaster } from 'evergreen-ui'
import { Card, CardHeader, Icon, FormError, Button, Spinner, useToken } from '@pearly/lib'
import OfficeFields from 'components/_fields/office-fields'

export type Props = {
  prev: string
  next: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingPractice = ({ prev, next, setIsForward }: Props) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { tokenResult, refreshToken } = useToken()
  const isCurrentStep = tokenResult?.claims.onboardingStep === Types.OnboardingStep.PRACTICE

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery<Types.OnboardingPractice>(
    GET_ONBOARDING_PRACTICE,
    { skip: isCurrentStep || loading }
  )

  const [submit, submitStatus] = useMutation<Types.SubmitOnboardingPractice, Types.SubmitOnboardingPracticeVariables>(
    SUBMIT_ONBOARDING_PRACTICE
  )
  useEffect(() => {
    if (submitStatus.error) {
      toaster.danger('Unable to create practice')
      setLoading(false)
    }

    if (submitStatus.data) {
      const moveForward = async () => {
        await refreshToken()
        setIsForward(true)
        history.push(next)
      }
      moveForward()
    }
  }, [submitStatus, next, history, setIsForward, refreshToken])

  const [update, updateStatus] = useMutation<Types.UpdateOnboardingPractice, Types.UpdateOnboardingPracticeVariables>(
    UPDATE_ONBOARDING_PRACTICE
  )
  useEffect(() => {
    if (updateStatus.error) {
      toaster.danger('Unable to update practice')
      setLoading(false)
    }

    if (updateStatus.data) {
      setIsForward(true)
      history.push(next)
    }
  }, [updateStatus, next, history, setIsForward])

  // Show spinner while loading query, for query error, or prior to initiating query
  return queryLoading || queryError || (!isCurrentStep && !queryData && !loading) ? (
    <Spinner paddingTop={0} delay={1200} />
  ) : (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up your Practice
        </Heading>
        <Pill color="blue">2/4</Pill>
      </CardHeader>
      <Formik
        initialValues={
          queryData
            ? {
                name: queryData.account?.name ?? '',
                address1: queryData.account?.offices[0].address1 ?? '',
                address2: queryData.account?.offices[0].address2 ?? '',
                city: queryData.account?.offices[0].city ?? '',
                state: queryData.account?.offices[0].state ?? ('' as Types.State),
                zip: queryData.account?.offices[0].zip ?? '',
                phone: queryData.account?.offices[0].phone ?? '',
                email: queryData.account?.offices[0].email ?? '',
              }
            : {
                name: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                email:''
              }
        }
        onSubmit={({ ...fields }) => {
          const fieldsWithTypedState = { ...fields, state: fields.state as Types.State }
          setLoading(true)
          
          if (queryData) {
            update({ variables: { ...fieldsWithTypedState, officeId: queryData.account?.offices[0].id ?? '' } })
          } else {
            submit({ variables: { ...fieldsWithTypedState } })
          }
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Practice name is required'),
          address1: Yup.string().required('Address is required'),
          address2: Yup.string().nullable(),
          city: Yup.string().required('City is required'),
          state: Yup.mixed()
            .required('State is required')
            .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation)'),
          zip: Yup.string()
            .required('Zip is required')
            .matches(/^[0-9]\d{4}$/, 'Please enter a valid zip code (5 digits)'),
          phone: Yup.string()
            .required('Phone is required')
            .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),
          email: Yup.string()
            .required('Email is required')
            .matches(/\S+@\S+\.\S+/, 'Please enter a valid email address')
        })}
      >
        <Form>
          <Pane padding={24} paddingBottom={8} display="flex" flexDirection="column" alignItems="center">
            <Icon icon={['fad', 'store-alt']} color="primary" size="3x" marginBottom={16} />
            <Text size={500} marginBottom={16}>
              Enter practice details (main office)
            </Text>
            <OfficeFields practice />
            <FormError />
          </Pane>

          <Pane zIndex={1} elevation={0} padding={16} flexShrink="0" display="flex" justifyContent="space-between">
            <Button
              appearance="minimal"
              height={48}
              justifyContent="center"
              iconBefore={['far', 'chevron-left']}
              onClick={() => {
                setIsForward(false)
                history.push(prev)
              }}
            >
              Back
            </Button>
            <Button
              isLoading={loading}
              type="submit"
              appearance="primary"
              height={48}
              justifyContent="center"
              iconAfter={['far', 'chevron-right']}
            >
              Next
            </Button>
          </Pane>
        </Form>
      </Formik>
    </Card>
  )
}

export default OnboardingPractice
