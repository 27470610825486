import {
  createModalProvider,
  ContractSheet,
  EnrollMemberSheet,
  ViewMemberSheet,
  ViewPaymentsProblemsSheet,
  ViewPracticePaymentsProblemsSheet,
  ViewServicesSheet,
  SendInviteDialog,
  ConfirmDialog,
  UpdatePlanDateSheet,
  PaymentRecipt
} from '@pearly/lib'

import DentistSheet from 'components/_sheets/dentist-sheet'
import OfficeSheet from 'components/_sheets/office-sheet'
import PlanSheet from 'components/_sheets/plan-sheet'
import UserSheet from 'components/_sheets/user-sheet'
import ClearentSheet from 'components/_sheets/clearent-sheet'
import BankSheet from './_sheets/bank-sheet'
import OwnerSheet from './_sheets/owner-sheet'
import OboardDashboardSheet from './_sheets/onboard-dashboard-sheet'
import PracticeSheet from './_sheets/practice-sheet'
import ViewPracticeInvoice from './_sheets/view-practice-invoice'
import FAQSheet from './_sheets/faq-sheet'
import VideoSheet from './_sheets/video-sheet'
import FlyerSheet from './_sheets/flyer-sheet'
import EmailHelpSheet from './_sheets/email-help'
import EmailAttachmentsSheet from './_sheets/email-attachments-sheet'
import EmailResendSheet from './_sheets/email-resend-sheet'

const sheetComponents = {
  enrollMember: EnrollMemberSheet,
  contract: ContractSheet,
  updatePlanDateSheet: UpdatePlanDateSheet,
  dentist: DentistSheet,
  office: OfficeSheet,
  plan: PlanSheet,
  clearent: ClearentSheet,
  bank: BankSheet,
  owner: OwnerSheet,
  dashboard: OboardDashboardSheet,
  practice: PracticeSheet,
  user: UserSheet,
  viewMember: ViewMemberSheet,
  viewPaymentsProblems: ViewPaymentsProblemsSheet,
  viewPracticePaymentsProblemsSheet: ViewPracticePaymentsProblemsSheet,
  viewServices: ViewServicesSheet,
  sendInvite: SendInviteDialog,
  paymentRecipt: PaymentRecipt,
  viewPracticeInvoice: ViewPracticeInvoice,
  FAQ: FAQSheet,
  video: VideoSheet,
  flyer: FlyerSheet,
  confirm: ConfirmDialog,
  emailHelp: EmailHelpSheet,
  emailAttachments: EmailAttachmentsSheet,
  emailResend: EmailResendSheet,
}

export const { ModalProvider, useModal } = createModalProvider(sheetComponents)
