import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_PLAN_SHEET, UPDATE_PLAN } from 'graphql/_plan-sheet'

import * as Types from 'types'

import { SideSheet, Pane, Tablist, Tab, Heading, toaster } from 'evergreen-ui'
import { CardHeader, Icon, Spinner } from '@pearly/lib'
import PlanStatusSelector from 'components/plan-status-selector'
import { useModal } from 'components/modal-provider'

import Plan from 'components/plan'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  planId: string
  plans: Types.Plan[]
}

const steps = [
  { path: 'fees', component: Plan.Fees },
  { path: 'treatments', component: Plan.Treatments },
  { path: 'discounts', component: Plan.Discounts },
  { path: 'terms', component: Plan.Terms }
]

const PlanSheet = ({ isShown, setIsShown, planId, plans }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [currentPath, setCurrentPath] = useState('fees')
  const CurrentView = steps.find(step => step.path === currentPath)!.component

  const [dirty, setDirty] = useState(false)

  const { loading, error, data } = useQuery<Types.PlanSheet, Types.PlanSheetVariables>(GET_PLAN_SHEET, {
    variables: { id: planId }
  })

  const [updatePlan, updatePlanStatus] = useMutation<Types.UpdatePlan, Types.UpdatePlanVariables>(UPDATE_PLAN)

  useEffect(() => {
    if (updatePlanStatus.error) toaster.danger(`Unable to update plan`)
    else if (updatePlanStatus.data && !updatePlanStatus.loading) {
      toaster.success('Plan successfully updated!')
      setDirty(false)
    }
  }, [updatePlanStatus])

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column'
      }}
      shouldCloseOnOverlayClick={false}
    >
      {loading || error || !data?.plan ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (
        <SheetLayout>
          <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
            <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={16}
              paddingRight={24}
              borderBottom="muted"
            >
              <Pane display="flex" alignItems="center">
                <Icon icon={['fad', 'files-medical']} size="2x" color="selected" paddingLeft={4} />
                <Pane marginLeft={16}>
                  <Heading size={600} width= "400px" wordBreak="break-word">{data.plan.name.substring(0, 64)}</Heading>
                </Pane>
              </Pane>
              <PlanStatusSelector plan={data.plan} />
            </Pane>
            <Pane display="flex" padding={8}>
              <Tablist>
                {steps.map(step => {
                  const { path } = step
                  return (
                    <Tab
                      key={path}
                      textTransform="uppercase"
                      isSelected={currentPath === path}
                      onSelect={() => {
                        if (dirty) {
                          showConfirmDialog({
                            title: 'Unsaved Changes',
                            body: 'Your plan has unsaved changes that will be lost.  Would you like to continue?',
                            intent: 'danger',
                            confirmLabel: 'Continue Without Saving',
                            confirm: () => {
                              setCurrentPath(path)
                              setDirty(false)
                            }
                          })
                        } else {
                          setCurrentPath(path)
                        }
                      }}
                    >
                      {path}
                    </Tab>
                  )
                })}
              </Tablist>
            </Pane>
          </CardHeader>
{console.log(data.plan)}
          <Pane gridArea="body" overflow="hidden">
            <CurrentView plan={data.plan} plans={plans} update={[updatePlan, updatePlanStatus]} dirtyState={[dirty, setDirty]} />
          </Pane>
        </SheetLayout>
      )}
    </SideSheet>
  )
}

export default PlanSheet

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`
