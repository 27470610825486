import React, { createContext, useContext, useReducer } from 'react'

import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from '@apollo/react-hooks'

import { objectEntriesGenericKey } from './transforms'

type SheetType =
  | 'enrollMember'
  | 'contract'
  | 'dentist'
  | 'office'
  | 'plan'
  | 'owner'
  | 'user'
  | 'viewMember'
  | 'viewServices'
  | 'viewPractice'
  | 'viewPaymentsProblems'
  | 'viewPracticePaymentsProblemsSheet'
  | 'sendInvite'
  | 'confirm'
  | 'clearent'
  | 'bank'
  | 'dashboard'
  | 'practice'
  | 'paymentRecipt'
  | 'viewPracticeInvoice'
  | 'FAQ'
  | 'video'
  | 'flyer'
  | 'emailHelp'
  | 'emailAttachments'
  | 'emailResend'

type SheetState = { show: boolean; sheetProps?: { [x: string]: unknown } }

type SheetsState = {
  [key in SheetType]: SheetState
}

// Unable to enforce isShown / setIsShown
type SheetComponent = (props: any) => JSX.Element
type SheetComponents = Partial<Record<SheetType, SheetComponent>>

type SheetsAction = { sheet: SheetType; show?: boolean; sheetProps?: { [x: string]: unknown } }

type CustomParameters<T> = T extends (...args: infer P) => any ? P : never
// type NonOptional<T> = T[keyof T] extends undefined ? never : T

type CreateModalProvider = <V extends SheetComponents>(
  sheetComponents: V
) => {
  ModalProvider: ModalProvider
  useModal: <T extends SheetType & keyof V>(
    sheet: T
  ) => // TODO Require props when sheet props object contains non-optional keys
  (sheetProps?: Omit<CustomParameters<V[T]>[0], 'isShown' | 'setIsShown'> | false) => void
}

type ModalProvider = (props: { children: React.ReactNode; apolloClient: ApolloClient<any> }) => JSX.Element

// Check whether keyof K restricts sheet type to those passed to CreateModalProvider
// type UseSheet<K extends SheetComponents> =
// ///////////////////////////////////////////////////////////////////////////////

export const createModalProvider: CreateModalProvider = sheetComponents => {
  const SheetContext = createContext({} as React.Dispatch<SheetsAction>)

  const initialSheetState: SheetsState = Object.keys(sheetComponents).reduce(
    (prev, curr) => ({ ...prev, [curr]: { show: false } }),
    {} as SheetsState
  )

  type SheetsReducer = (state: SheetsState, action: SheetsAction) => SheetsState
  const reducer: SheetsReducer = (state, { sheet, show = true, sheetProps }) => {
    return { ...state, [sheet]: { show, sheetProps } }
  }

  const ModalProvider: ModalProvider = ({ children, apolloClient }) => {
    const [sheetsState, sheetsAction] = useReducer(reducer, initialSheetState)
    return (
      <SheetContext.Provider value={sheetsAction}>
        <ApolloProvider client={apolloClient}>
          {objectEntriesGenericKey<never>(sheetsState)
            .filter(([_type, state]) => state.show)
            .map(([type, state]: [never, SheetState], i) => {
              const Component = sheetComponents[type] as SheetComponent
              return (
                <Component
                  key={i}
                  isShown={state.show}
                  setIsShown={(isShown: boolean) => sheetsAction({ sheet: type, show: isShown })}
                  {...state.sheetProps}
                />
              )
            })}
        </ApolloProvider>
        {children}
      </SheetContext.Provider>
    )
  }

  return {
    ModalProvider,
    useModal: sheet => {
      // returns a function that takes a single optional parameter representing either
      // 1) a set of sheet props, which whill show the sheet with those props
      // 2) 'false', which will hide the sheet
      // 3) undefined, which will show the sheet
      const sheetsAction = useContext(SheetContext)
      return sheetPropsOrFalse => {
        return sheetPropsOrFalse === false
          ? sheetsAction({ sheet, show: false })
          : sheetsAction({
              sheet,
              show: true,
              sheetProps: sheetPropsOrFalse
            })
      }
    }
  }
}
