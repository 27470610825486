import React, { useEffect, useState } from 'react'
import * as Types from 'types'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_LIVE_PLANS, GET_LEGACY_PLANS, GET_DRAFT_PLANS } from 'graphql/_plans'
import { SET_PLAN_STATUS, DELETE_PLAN } from 'graphql/_plan-status-selector'

import { Badge, Popover, Menu, Position, toaster, Dialog, Pane, Text } from 'evergreen-ui'
import { Button, ContractSheet } from '@pearly/lib'

import { useGlobal } from 'components/global-provider'
import { useModal } from 'components/modal-provider'

export type Props = {
  plan: {
    id: string
    name: string
    status: Types.PlanStatus
    allTimeMemberCount: number
  }
}

const statusColors = {
  [Types.PlanStatus.LIVE]: { badge: 'green' as const, button: 'success' },
  [Types.PlanStatus.LEGACY]: { badge: 'orange' as const, button: 'warning' },
  [Types.PlanStatus.DRAFT]: { badge: 'blue' as const, button: 'none' },
  [Types.PlanStatus.TEMPLATE]: { badge: 'neutral' as const, button: 'success' }
}

const statusQueries = {
  [Types.PlanStatus.LIVE]: GET_LIVE_PLANS,
  [Types.PlanStatus.LEGACY]: GET_LEGACY_PLANS,
  [Types.PlanStatus.DRAFT]: GET_DRAFT_PLANS,
  [Types.PlanStatus.TEMPLATE]: null
}

const PlanStatusSelector = ({ plan }: Props) => {
  const global = useGlobal()
  const showPlanSheet = useModal('plan')
  const showConfirmDialog = useModal('confirm')
  const [isLoading, setIsLoading] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const statusColor = statusColors[plan.status]
  const isTemplate = plan.status === Types.PlanStatus.TEMPLATE

  const [isContractSheetShown, setIsContractSheetShown] = useState(false)

  const { data: livePlans } = useQuery<Types.LivePlans>(GET_LIVE_PLANS)
  const { data: draftPlans } = useQuery<Types.DraftPlans>(GET_DRAFT_PLANS)
  const { data: legacyPlans } = useQuery<Types.LegacyPlans>(GET_LEGACY_PLANS)

  const [setPlanStatus, setPlanStatusStatus] = useMutation<Types.SetPlanStatus, Types.SetPlanStatusVariables>(
    SET_PLAN_STATUS,
    {
      update(cache, { data }) {
        if (!data?.setPlanStatus) return
        const newPlan = data.setPlanStatus

        const removalQuery = statusQueries[plan.status]
        const removalCachedData = cache.readQuery<Types.LivePlans | Types.LegacyPlans | Types.DraftPlans>({
          query: removalQuery
        })
        if (removalCachedData) {
          cache.writeQuery({
            query: removalQuery,
            data: {
              ...removalCachedData,
              plans: removalCachedData.plans.filter(listedPlan => listedPlan.id !== plan.id)
            }
          })
        }

        try {
          const additionQuery = statusQueries[newPlan.status]
          const additionCachedData = cache.readQuery<Types.LivePlans | Types.LegacyPlans | Types.DraftPlans>({
            query: additionQuery
          })
          if (additionCachedData) {
            cache.writeQuery({
              query: additionQuery,
              data: { ...additionCachedData, plans: additionCachedData.plans.concat(newPlan) }
            })
          }
        } catch (err) {
          if (err.name !== 'Invariant Violation') throw err
        } finally {
          setIsLoading(false)
          toaster.success(`Plan status succesfully updated to ${data.setPlanStatus.status}`)
        }
      }
    }
  )

  useEffect(() => {
    if (setPlanStatusStatus.error) {
      setIsLoading(false)
      toaster.danger('Unable to set plan status')
    }
  }, [setPlanStatusStatus])

  const [deletePlan, deletePlanStatus] = useMutation<Types.DeletePlan, Types.DeletePlanVariables>(DELETE_PLAN, {
    refetchQueries: ['ServicePlans'],
    variables: { id: plan.id },
    update: (cache, { data }) => {
      const query = statusQueries[plan.status]
      const cachedData = cache.readQuery<Types.LivePlans | Types.LegacyPlans | Types.DraftPlans>({ query })
      if (data && cachedData) {
        cache.writeQuery({
          query,
          data: { ...cachedData, plans: cachedData.plans.filter(listedPlan => listedPlan.id !== data.deletePlan?.id) }
        })

        toaster.danger(`${plan.name} has been successfully deleted!`)
        showPlanSheet(false)
      }
    }
  })

  useEffect(() => {
    if (deletePlanStatus.error) toaster.danger('Unable to delete plan')
  }, [deletePlanStatus, plan.name, showPlanSheet])

  return isTemplate ? (
    <Badge color={statusColor.badge} marginRight={6}>
      {plan.status}
    </Badge>
  ) : (
    <>
      <ContractSheet isShown={isContractSheetShown} setIsShown={setIsContractSheetShown} planId={plan.id} />

      <Popover
        position={Position.BOTTOM_RIGHT}
        trigger="click"
        minWidth={120}
        content={({ close }) => (
          <Menu>
            {global.account?.acceptPayments && (
              <>
                <Menu.Group>
                  {(plan.status === Types.PlanStatus.DRAFT || plan.status === Types.PlanStatus.LEGACY) && (
                    <Menu.Item
                      onSelect={() => {
                        let planName = livePlans?.plans.find(x => x.name === plan.name)?.name
                        if (planName === plan.name) {
                          return toaster.warning('Plan is already available')
                        }
                        close()
                        showConfirmDialog({
                          body:
                            'Are you sure you want to make this plan live?  Patients will be able to enroll immediately.',
                          confirm: () => {
                            setIsLoading(true)
                            setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.LIVE } })
                          }
                        })
                      }}
                    >
                      <Badge color="green" marginBottom={3}>
                        Live
                      </Badge>
                    </Menu.Item>
                  )}
                  {plan.status === Types.PlanStatus.LIVE && plan.allTimeMemberCount > 0 && (
                    <Menu.Item
                      onSelect={() => {
                        // let planName = legacyPlans?.plans.filter(x => (x.name === plan.name || x.name === `${plan.name} - Legacy`))
                        // console.log("🚀 ~ file: plan-status-selector.tsx:168 ~ PlanStatusSelector ~ planName:", planName)
                        // if (planName?.length) {
                        //   let name = plan.name.trim()
                        //   for (let i = 0; i < planName.length; i++) {
                        //     const element = planName[i];
                        //     if (element.name.includes("Legacy"))
                        //       name = `${name} (${planName.length})`
                        //     else
                        //       name = `${name} - Legacy`
                        //   }
                        //   setPlanName(name)
                        //   setConfirmed(true)
                        // } else {
                        //   close()
                        //   setIsLoading(true)
                        //   setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.LEGACY } })
                        // }

                        let planName = legacyPlans?.plans.find(x => x.name === plan.name)?.name
                        if (planName === plan.name) {
                          setConfirmed(true)
                        } else {
                          close()
                          setIsLoading(true)
                          setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.LEGACY } })
                        }
                      }}
                    >
                      <Badge color="orange" marginBottom={3}>
                        Legacy
                      </Badge>
                    </Menu.Item>
                  )}
                  {plan.allTimeMemberCount === 0 &&
                    (plan.status === Types.PlanStatus.LIVE || plan.status === Types.PlanStatus.LEGACY) && (
                      <Menu.Item
                        onSelect={() => {
                          let planName = draftPlans?.plans.find(x => x.name === plan.name)?.name
                          if (planName === plan.name) {
                            return toaster.warning('Plan is already available')
                          }
                          close()
                          showConfirmDialog({
                            body: `Are you sure you want to set this plan's status to draft?`,
                            confirm: () => {
                              setIsLoading(true)
                              setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.DRAFT } })
                            }
                          })
                        }}
                      >
                        <Badge color="blue" marginBottom={3}>
                          Draft
                        </Badge>
                      </Menu.Item>
                    )}
                </Menu.Group>
                <Menu.Divider />
              </>
            )}

            <Menu.Group>
              <Menu.Item onSelect={() => setIsContractSheetShown(true)}>View Contract</Menu.Item>
              {plan.allTimeMemberCount === 0 && plan.status !== Types.PlanStatus.TEMPLATE && (
                <Menu.Item
                  intent="danger"
                  onSelect={() => {
                    close()
                    showConfirmDialog({
                      body: 'Are you sure you want to delete this plan?',
                      confirm: () => {
                        setIsLoading(true)
                        deletePlan()
                      }
                    })
                  }}
                >
                  Delete
                </Menu.Item>
              )}
            </Menu.Group>
          </Menu>
        )}
      >
        <Button
          isLoading={isLoading}
          data-undraggable
          appearance="minimal"
          intent={statusColor.button}
          iconAfter={['far', 'chevron-down']}
        >
          <Badge data-undraggable color={statusColor.badge}>
            {plan.status}
          </Badge>
        </Button>
      </Popover>
      {confirmed && <Dialog
        isShown={confirmed}
        title={'Confirmation'}
        onConfirm={() => {
          // close()
          // let planNames = legacyPlans?.plans.filter(x => x.name === plan.name.trim());
          // console.log("🚀 ~ file: plan-status-selector.tsx:168 ~ PlanStatusSelector ~ planName:", planNames)
          // if (planNames?.length) {
          //   setPlanNameError("This name already exists in Legacy Plans. Please rename to continue.")
          // } else {
          // setPlanNameError("")
          setIsLoading(true)
          setPlanStatus({ variables: { id: plan.id, status: Types.PlanStatus.LEGACY } })
          setConfirmed(false)
          // };
        }}
        onCancel={() => {
          // close()
          // setPlanNameError("")
          setIsLoading(false)
          setConfirmed(false)
        }}
      >
        <Pane padding={16}>
          <Text>It seems that a plan with similar name already exists in your Legacy Plans. Are you sure that you still want to move? </Text><br />
          <Text margin={"auto"}>If you are sure, please click Confirm, otherwise click Cancel.</Text>
          {/* <TextInputField value={planName} onChange={(e: any) => setPlanName(e.target.value)} marginTop={10} flex={1} name="planName" height={40} placeholder="Plan Name" label="Plan Name" /> */}
          {/* {planNameError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{planNameError}</Text></div>} */}
        </Pane>
      </Dialog>}
    </>
  )
}

export default PlanStatusSelector
