import React from 'react'
import styled from 'styled-components/macro'
import { SideSheet, Pane, Text, Heading, toaster } from 'evergreen-ui'
import { Card, CardHeader } from '@pearly/lib'


export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void,
    templateCode: string
}

const EmailHelpSheet = ({ isShown, setIsShown, templateCode }: Props) => {
    const variables = [
        {
            name: "#PRACTICEADMIN#",
            description: "Email addresses of Admins of the concerned practice account.",
            temps: [],
            usedFor: ["to", "cc", "bcc", "subject", "body"]
        },
        {
            name: "#PRACTICESTANDARDADMIN#",
            description: "Email addresses of Standard Admins of the concerned practice account.",
            temps: [],
            usedFor: ["to", "cc", "bcc", "subject", "body"]
        },
        {
            name: "#PRACTICENAME#",
            description: "Name of concerned practice account.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PRACTICECODE#",
            description: "Practice Code of the concerned practice account.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DENTISTNAME#",
            description: "Dentist Name of concerned practice account.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PRACTICEOFFICEPHONE#",
            description: "Practice Office Phone Number of the concerned practice account.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PRACTICEOFFICEEMAIL#",
            description: "Practice Office Email of the concerned practice account.",
            temps: [],
            usedFor: ["to", "cc", "bcc", "subject", "body"]
        },
        {
            name: "#PRACTICEOFFICEADDRESS#",
            description: "Practice Office Address of the concerned practice account.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PFHSUBSCRIPTIONACTIVATIONDATE#",
            description: "Plan For Health subscription activation date.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PFHSUBSCRIPTIONACCEPTANCEDATE#",
            description: "Plan For Health subscription agreement acceptance date.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PFHSUBSCRIPTIONCANCELLATIONREQUESTDATE#",
            description: "Plan For Health subscription cancellation Request date.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PFHSUBSCRIPTIONCANCELLATIONDATE#",
            description: "Plan For Health subscription cancellation date.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PLANNAME#",
            description: "Plan For Health plan name.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#FAILEDPAYMENTDATE#",
            description: "Failed Payment Date.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#INVOICEDATE#",
            description: "Failed Payment Invoice Date.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DATE#",
            description: "Current date in MMDDYY format.",
            temps: [],
            usedFor: ["subject", "body"]
        },
    ]

    const copyToClipboard = (TextToCopy: any) => {
        var TempText = document.createElement("input");
        TempText.value = TextToCopy;
        document.body.appendChild(TempText);
        TempText.select();

        document.execCommand("copy");
        document.body.removeChild(TempText);
        navigator.clipboard.writeText(TempText.value);
        toaster.success(`${TempText.value} is copied to clipboard`)
    }

    return (
        <>
            <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={733} shouldCloseOnOverlayClick={false}>
                <SheetLayout>
                    <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                        <Pane display="flex" alignItems="center">
                            <Pane marginLeft={16}>
                                <Heading size={600}>
                                    Email Help
                                </Heading>
                                <Text size={400}></Text>
                            </Pane>
                        </Pane>
                        <Text size={400}>
                            {/* <strong>ID: </strong>
                            kk */}
                        </Text>
                    </Pane>
                    <Pane gridArea="body" overflow="hidden" background="blueTint">
                        <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                            <CardHeader>
                                <Heading size={500}>Variables</Heading>
                            </CardHeader>
                            <Pane padding={16} overflow="scroll" height={'calc(100vh - 125px)'}>
                                <Text size={400}>These variable are customised/tailor made solutions for composing the email message  body and each variable can be clicked to copy and add into message body.</Text>
                                <Card backgroundColor="white" elevation={0} marginTop={10} marginBottom={20} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Recipients (To, Cc, and Bcc)</Heading>
                                    </CardHeader>
                                    <Pane padding={10}>

                                        {(variables.length && templateCode) ?
                                            variables
                                                .filter(item => item.usedFor.includes("to") || item.usedFor.includes("cc") || item.usedFor.includes("bcc"))
                                                .map((item, i) =>
                                                    <Text size={500} marginBottom={8} style={{ display: "flex" }}>
                                                        <strong>{i + 1}. </strong>
                                                        <strong style={{ marginLeft: '5px', cursor: "pointer" }} onClick={(e) => copyToClipboard(item.name)} >
                                                            {item.name}:
                                                        </strong>
                                                        <span style={{ marginLeft: '5px' }}>{item.description}</span>
                                                    </Text>
                                                ) :
                                            <></>
                                        }
                                    </Pane>
                                </Card>
                                <Card backgroundColor="white" elevation={0} margin={0} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Subject and Body</Heading>
                                    </CardHeader>
                                    <Pane padding={10}>

                                        {(variables.length && templateCode) ?
                                            variables
                                                .filter(item => item.usedFor.includes("subject") || item.usedFor.includes("body"))
                                                .map((item, i) =>
                                                    <Text size={500} marginBottom={8} style={{ display: "flex" }}>
                                                        <strong>{i + 1}. </strong>
                                                        <strong style={{ marginLeft: '5px', cursor: "pointer" }} onClick={(e) => copyToClipboard(item.name)} >
                                                            {item.name}:
                                                        </strong>
                                                        <span style={{ marginLeft: '5px' }}>{item.description}</span>
                                                    </Text>
                                                ) :
                                            <></>
                                        }
                                    </Pane>
                                </Card>
                            </Pane>
                        </Card>
                    </Pane>
                </SheetLayout>
            </SideSheet>
        </>
    )
}

export default EmailHelpSheet

const SheetLayout = styled.div`
            height: 100%;
            overflow: hidden;
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-areas:
            'header'
            'body'
            'footer';
            `