import React from 'react'

import { TextInputField, Textarea, RadioGroup } from '@pearly/lib'

import { FAQStatus } from 'types'
import { Label } from 'evergreen-ui'

export type Props = {}

const FAQFields = ({ ...props }: Props) => (
  <>
    <Label marginBottom={4} alignSelf="flex-start">Question</Label>
    <Textarea name="question" label="Question" type="text" placeholder="Question" />
    <Label marginBottom={4} alignSelf="flex-start">Answer</Label>
    <Textarea name="answer" label="Answer" type="text" placeholder="Answer" />
    <TextInputField name="index" label="Index" type="number" placeholder="Index" />
    <RadioGroup
      name="status"
      label="Status"
      size={16}
      marginBottom={0}
      options={[
        { label: 'Active', value: FAQStatus.ACTIVE },
        { label: 'Inactive', value: FAQStatus.INACTIVE }
      ]}
    />
  </>
)

export default FAQFields
