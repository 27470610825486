import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { Pane, Heading, Label, TextInput, Button, TagInput, SelectMenu, toaster, Text, SelectMenuItem, SideSheet } from 'evergreen-ui'
import { Card, Icon, Spinner } from '@pearly/lib'
import { useLocation } from 'react-router-dom'
import ReactQuill from 'react-quill';
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import * as Types from 'types/graphql'
import { GET_USERS_TABLE } from 'graphql/_users-table'
import { EmailCategoryType } from 'types'
import { useGlobal } from 'components/global-provider'
import { GET_EMAIL_LOG, RESEND_EMAIL } from 'graphql/_email-logs'
import moment from 'moment'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  id: string
  getEmailLogs: () => void
}

const EmailResendSheet = ({ isShown, setIsShown, id, getEmailLogs }: Props) => {

  const location = useLocation()
  const quillref = useRef<any>(null)
  const global = useGlobal()
  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM
  const isSuperAdmin = (global.meUser?.google.role === Types.UserRole.SUPERADMIN)

  const category = isSystemAccount ? location.pathname.split('/')[2].toLocaleUpperCase() : EmailCategoryType.EXTERNAL;

  const { data, error, networkStatus, loading } = useQuery<Types.GetEmailLog>(GET_EMAIL_LOG, {
    variables: { id: id },
    fetchPolicy: "no-cache"
  });

  const [body, setBody] = React.useState<any>('')
  const [isErrors, setIsErrors] = React.useState<any>({})
  const [emailData, setEmailData] = React.useState<any>({
    sr: 0,
    sentOn: "",
    id: id,
    subject: "",
    description: "",
    userType: "",
    body: "",
    status: "",
  })

  const [selectedTo, setSelectedTo] = React.useState<string[]>([])
  const [selectedCC, setSelectedCC] = React.useState<string[]>([])
  const [selectedBCC, setSelectedBCC] = React.useState<string[]>([])
  const [selectedAttachments, setSelectedAttachments] = React.useState<any[]>([])

  const { data: userData, error: userError, networkStatus: userNetworkStatus, loading: userLoading } = useQuery<Types.UsersTable>(GET_USERS_TABLE)

  const [users, setUsers] = React.useState<any[]>([])
  const [externaltoRecipients, setExternaltoRecipients] = React.useState<any[]>([])

  useEffect(() => {
    if (data && data.getEmailLog) {
      setEmailData(data.getEmailLog)
      setSelectedTo(data.getEmailLog?.to)
      setSelectedCC(data.getEmailLog?.cc)
      setSelectedBCC(data.getEmailLog?.bcc)
      setBody(data.getEmailLog?.body)
      if (data.getEmailLog?.files) {
        const parsedFiles = JSON.parse(data.getEmailLog.files)
        if (parsedFiles && parsedFiles.length) {
          setSelectedAttachments(parsedFiles.map((file: any) => ({
            label: file.name,
            value: file.url,
            status: file.status,
          })))
        }
      }
      if (data.getEmailLog.accountUsers.length) {
        setExternaltoRecipients(data.getEmailLog.accountUsers.map((email) => ({
          label: email,
          value: email
        })))
      }
    }
  }, [data])

  useEffect(() => {
    if (userData && userData?.users) {
      setUsers(userData?.users?.map((user) => ({
        label: user?.firstName + " " + user?.lastName + " (" + user?.google?.email + ")",
        value: user?.google?.email
      })))
    }
  }, [userData])

  const findSelectedCount = (data: any[]) => {
    const count = data.filter(x => users.map(x => x.value).indexOf(x) !== -1).length
    return count > 0 ? `${count} selected` : 'System Users'
  }

  const findExternalToFieldSelectedCount = (data: any[]) => {
    const count = data.filter(x => externaltoRecipients.map(x => x.value).indexOf(x) !== -1).length
    return count > 0 ? `${count} selected` : 'Practice Users'
  }

  const handleChange = (value: any, name: any) => {
    setEmailData({ ...emailData, [name]: value })
  }

  const validation = () => {
    let flag = true;
    let errors: any = {}
    if (!selectedTo.length) {
      errors["to"] = "please enter To";
      flag = false
    }
    if (!emailData?.subject) {
      errors["subject"] = "please enter subject";
      flag = false
    }
    if (!body) {
      errors["body"] = "please enter body";
      flag = false
    }
    setIsErrors(errors)
    return flag
  }

  const [
    resendEmail,
    {
      data: resendData,
      error: resendError,
      loading: resendLoading,
    }
  ] = useLazyQuery<Types.ResendEmail>(RESEND_EMAIL, {
    context: { secure: true },
    variables: {
      ...emailData,
      to: JSON.stringify(selectedTo),
      cc: JSON.stringify(selectedCC),
      bcc: JSON.stringify(selectedBCC),
      files: emailData.files ?? "",
      // files: JSON.stringify(files),
      body: body?.includes("<ul>") ?
        body?.replace(new RegExp("<p><br></p><ul>", 'gi'), "<ul>")
        :
        body?.includes("<ol>") ?
          body?.replace(new RegExp("<p><br></p><ol>", 'gi'), "<ol>")
          : body
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  })


  useEffect(() => {
    if (resendError) toaster.danger(`Unable to resend email`)
    else if (resendData && !resendLoading) {
      toaster.success(`Email resend successfully updated!`)
      setIsShown(false)
      getEmailLogs()
    }
  }, [resendData, resendError, resendLoading])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={800} shouldCloseOnOverlayClick={false}>

      {loading || userLoading ?
        <Pane height={84} paddingY={24}>
          < Spinner delay={0} />
        </Pane > :
        <SheetLayout>

          <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
            <Pane display="flex" alignItems="center" width="70%">
              <Pane marginLeft={16}>
                <Heading size={600}>
                  Resend Email
                </Heading>
                <Text size={400}>{emailData?.emailId}: {emailData?.action}</Text>
              </Pane>
            </Pane>
            <Text size={500}>
              <strong>Sent on: </strong>
              {emailData?.sentDate ? moment(emailData.sentDate).format("M/D/YYYY") : '-'}
            </Text>
          </Pane>
          <Pane gridArea="body" overflow="scroll" background="blueTint">
            <Card backgroundColor="white" elevation={0} margin={16} padding={20}>
              <>
                <Pane marginTop={15} display="flex" width="100%">
                  <Label width="5%">To</Label>
                  <TagInput
                    disabled={!isSuperAdmin}
                    width="100%"
                    tagProps={(value: any) => {
                      if (category === Types.EmailCategoryType.EXTERNAL) {
                        if ((externaltoRecipients.map(x => x.value).indexOf(value) === -1) && (value[0] !== "#" || value[value.length - 1] !== "#")) {
                          return { color: 'orange' }
                        }
                      } else if ((users.map(x => x.value).indexOf(value) === -1) && (value[0] !== "#" || value[value.length - 1] !== "#")) {
                        return { color: 'orange' }
                      }
                      return {}
                    }}
                    inputProps={{ placeholder: 'To...' }}
                    values={selectedTo}
                    onAdd={(newValues) => {
                      if (category === EmailCategoryType.INTERNAL && newValues.some((val) => (users.map(x => x.value).indexOf(val) === -1)) || category !== EmailCategoryType.INTERNAL && newValues.some((val) => (externaltoRecipients.map(x => x.value).indexOf(val) === -1))) {
                        toaster.danger('Oops, you tried entering an invalid email. Try again.');
                        return;
                      }
                      setSelectedTo([...selectedTo, ...newValues]);
                    }}
                    onRemove={(_value, index) => {
                      setSelectedTo(selectedTo.filter(_item => _item !== _value))
                    }}
                  />
                  <SelectMenu
                    isMultiSelect
                    title="Select multiple names"
                    filterPlaceholder="Search..."
                    options={category === EmailCategoryType.EXTERNAL ? externaltoRecipients : users}
                    selected={selectedTo}
                    onSelect={(item: any) => setSelectedTo([...selectedTo, item?.value])}
                    onDeselect={(item: SelectMenuItem) => setSelectedTo(selectedTo.filter(_item => _item !== item?.value))}
                  >
                    <Button disabled={!isSuperAdmin} width="18%">{category === Types.EmailCategoryType.EXTERNAL ? findExternalToFieldSelectedCount(selectedTo) : findSelectedCount(selectedTo)}</Button>
                  </SelectMenu>
                </Pane >
                <Pane marginTop={15} display="flex" >
                  <Label width="5%">Cc</Label>
                  <TagInput
                    disabled={!isSuperAdmin}
                    width="100%"
                    tagProps={(value: any) => {
                      if ((users.map(x => x.value).indexOf(value) === -1) && (value[0] !== "#" || value[value.length - 1] !== "#")) return { color: 'orange' }
                      return {}
                    }}
                    inputProps={{ placeholder: 'Cc...' }}
                    values={selectedCC}
                    onAdd={(newValues) => {
                      if (newValues.some((val) => (users.map(x => x.value).indexOf(val) === -1))) {
                        toaster.danger('Oops, you tried entering an invalid email. Try again.');
                        return;
                      }
                      setSelectedCC([...selectedCC, ...newValues])
                    }}
                    onRemove={(_value, index) => {
                      setSelectedCC(selectedCC.filter(_item => _item !== _value))
                    }}
                  />
                  <SelectMenu
                    isMultiSelect
                    title="Select multiple names"
                    options={users}
                    selected={selectedCC}
                    onSelect={(item: any) => setSelectedCC([...selectedCC, item?.value])}
                    onDeselect={(item: SelectMenuItem) => setSelectedCC(selectedCC.filter(_item => _item !== item?.value))}
                  >
                    <Button disabled={!isSuperAdmin} width="18%">{findSelectedCount(selectedCC)}</Button>
                  </SelectMenu>
                </Pane >
                <Pane marginTop={15} display="flex" >
                  <Label width="5%">Bcc</Label>
                  <TagInput
                    disabled={!isSuperAdmin}
                    width="100%"
                    tagProps={(value: any) => {
                      if ((users.map(x => x.value).indexOf(value) === -1) && (value[0] !== "#" || value[value.length - 1] !== "#")) return { color: 'orange' }
                      return {}
                    }}
                    inputProps={{ placeholder: 'Bcc...' }}
                    values={selectedBCC}
                    onAdd={(newValues) => {
                      if (newValues.some((val) => (users.map(x => x.value).indexOf(val) === -1))) {
                        toaster.danger('Oops, you tried entering an invalid email. Try again.');
                        return;
                      }
                      setSelectedBCC([...selectedBCC, ...newValues])
                    }}
                    onRemove={(_value, index) => {
                      setSelectedBCC(selectedBCC.filter(_item => _item !== _value))
                    }}
                  />
                  <SelectMenu
                    isMultiSelect
                    title="Select multiple names"
                    options={users}
                    selected={selectedBCC}
                    onSelect={(item: any) => setSelectedBCC([...selectedBCC, item?.value])}
                    onDeselect={(item: SelectMenuItem) => setSelectedBCC(selectedBCC.filter(_item => _item !== item?.value))}
                  >
                    <Button disabled={!isSuperAdmin} width="18%">{findSelectedCount(selectedBCC)}</Button>
                  </SelectMenu>
                </Pane>
              </>

              <Pane marginBottom={15} marginTop={15}>
                <Label width="90px">Subject</Label>
                <TextInput
                  // disabled={!isSuperAdmin}
                  disabled
                  width="100%" value={emailData?.subject} onChange={(e: any) => handleChange(e.target.value, "subject")} name={'subject'} />
              </Pane >
              <Pane marginBottom={15}>
                <Label width="90px">Body</Label>
                <ReactQuill
                  ref={quillref}
                  style={{ width: '100%' }}
                  placeholder='Add description'
                  theme="snow" value={body}
                  onChange={(e) => {
                    setBody(e);
                  }}
                  readOnly
                />
              </Pane >
              <Pane marginTop={15}>
                <Label width="90px">Attachment</Label>
                <Pane display='flex'>
                  <TagInput
                    width="100%"
                    inputProps={{ placeholder: 'attachment' }}
                    values={selectedAttachments.filter(ele => ele.status === Types.EmailAttachmentsStatus.ACTIVE).map(attachment => attachment?.label)}
                    disabled
                  />
                </Pane>
              </Pane>
              {Object.keys(isErrors).map(item =>
                <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{isErrors[item]}</Text></div>
              )}
            </Card>
          </Pane>

          {isSuperAdmin ?
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              <Button
                isLoading={resendLoading}
                marginLeft={0}
                appearance="primary"
                justifyContent="center"
                height={40}
                onClick={() => {
                  if (validation()) {
                    resendEmail()
                  }
                }}
              >
                Resend
              </Button>
            </Pane>
            :
            <></>}

        </SheetLayout>
      }
    </SideSheet >
  )
}

export default EmailResendSheet
const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
