import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { SideSheet, Pane, Text, Heading, toaster } from 'evergreen-ui'
import { Card, CardHeader, Button, useToken, ConfirmDialog } from '@pearly/lib'
import { useMutation } from '@apollo/react-hooks'
import { SET_CURRENT_ACCOUNT_ID } from 'graphql/_header'
import { CLOSE_ACCOUNT, REVERT_ACCOUNT } from 'graphql/_onboarding-dashboard'

import * as Types from 'types'
import { useApolloClient } from '@apollo/react-hooks'


export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void
    practice: Types.Practice
}

const PracticeSheet = ({ isShown, setIsShown, practice }: Props) => {
    const apolloClient = useApolloClient()
    const { refreshToken } = useToken()
    const [setAccount, setAccountStatus] = useMutation<Types.SetCurrentAccountId, Types.SetCurrentAccountIdVariables>(
        SET_CURRENT_ACCOUNT_ID
    )

    const [closeAccount, closeAccountStatus] = useMutation<Types.closeAccount, Types.closeAccountVariables>(
        CLOSE_ACCOUNT,
        {
            refetchQueries: ['OwnServicePlanSubscriptionsSearch']
        }
    )

    const [revertAccount, revertAccountStatus] = useMutation<Types.revertAccount, Types.revertAccountVariables>(
        REVERT_ACCOUNT,
        {
            refetchQueries: ['OwnServicePlanSubscriptionsSearch']
        }
    )
    const [isCloseAccountDialogShown, setIsCloseAccountDialogShown] = useState(false)
    const [isRevertAccountDialogShown, setIsRevertAccountDialogShown] = useState(false)

    useEffect(() => {
        if (setAccountStatus.error) {
            toaster.danger(`Unable to switch accounts`)
        } else if (setAccountStatus.data && !setAccountStatus.loading) {
            refreshToken()
            setTimeout(() => {
                toaster.success(`Switched Practice to ${setAccountStatus.data?.setCurrentAccountId}`)
            }, 500)
        }
    }, [setAccountStatus, refreshToken, apolloClient])

    useEffect(() => {
        if (closeAccountStatus.error) {
            toaster.danger(`Unable to close account`)
        } else if (closeAccountStatus.data) {
            toaster.success(`Account ${closeAccountStatus.data.closeAccount.name} will be closed by the end of the month.`)
            setIsShown(false)
        }
    }, [closeAccountStatus, setIsShown])

    useEffect(() => {
        if (revertAccountStatus.error) {
            toaster.danger(`Unable to revert account`)
        } else if (revertAccountStatus.data) {
            toaster.success(`Account ${revertAccountStatus.data.revertAccount.name} is reverted.`)
            setIsShown(false)
        }
    }, [revertAccountStatus, setIsShown])

    return (
        <>
        <ConfirmDialog
            isShown={isCloseAccountDialogShown}
            setIsShown={setIsCloseAccountDialogShown}
            confirm={() => closeAccount({variables: {id: practice.accountId}})}
            body="You are about to close the account for this practice. By the end of this month, the Practice can no longer collect money from their members."
            intent="danger"
        />
        <ConfirmDialog
            isShown={isRevertAccountDialogShown}
            setIsShown={setIsRevertAccountDialogShown}
            confirm={() =>  revertAccount({variables: {id: practice.accountId}})}
            body="You are about to revert a close-pending account."
            intent="danger"
        />
        <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={500} shouldCloseOnOverlayClick={false}>
            <SheetLayout>
                <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                    <Pane display="flex" alignItems="center">
                        <Pane marginLeft={16}>
                            <Heading size={600}>
                                {practice.practiceName}
                            </Heading>
                            <Text size={400}>Practice Overview</Text>
                        </Pane>
                    </Pane>
                    <Text size={400}>
                        {/* <strong>ID: </strong>
                            kk */}
                    </Text>
                </Pane>
                <Pane gridArea="body" overflow="hidden" background="blueTint">
                    <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                        <CardHeader>
                            <Heading size={500}>Practice Details</Heading>
                        </CardHeader>
                        <Pane padding={16} display="flex" flexDirection="column">
                            <Text size={500} marginBottom={8}>
                                <strong>Plan: </strong>
                                {practice.plan}
                            </Text>
                            <Text size={500} marginBottom={8}>
                                <strong>Subscription Status: </strong>
                                {practice.status}
                            </Text>
                            <Text size={500} marginBottom={8}>
                                <strong>Account Status: </strong>
                                {practice.accountStatus}
                            </Text>

                        </Pane>
                    </Card>
                </Pane>
                <Pane
                    gridArea="footer"
                    elevation={0}
                    padding={16}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button marginLeft={0}
                        appearance="primary"
                        justifyContent="center"
                        height={40}
                        onClick={() => {
                            setAccount({ variables: { accountId: practice.accountId } })
                        }}>
                        Impersonate
                    </Button>
                    {
                     practice.accountStatus === 'ACTIVE'?   
                        <Button marginLeft={0}
                            appearance="primary"
                            justifyContent="center"
                            height={40}
                            onClick={() => {
                                setIsCloseAccountDialogShown(true)
                            }}>
                            Close Account
                        </Button>:<></>
                    }
                    {
                     practice.accountStatus === 'CLOSE_PENDING'?   
                        <Button marginLeft={0}
                            appearance="primary"
                            justifyContent="center"
                            height={40}
                            onClick={() => {
                                setIsRevertAccountDialogShown(true)
                            }}>
                            Revert Closing
                        </Button>:<></>
                    }
                </Pane>
            </SheetLayout>
        </SideSheet>
        </>
    )
}

export default PracticeSheet

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body'
    'footer';
`