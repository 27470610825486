import React from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Label } from 'evergreen-ui'
import { Button, Card, CardHeader, FormError, TextInputField, TextInput } from '@pearly/lib'
import { useModal } from 'components/modal-provider'

export type Props = {
  merchantSetting: Types.clearentMerchantSetting
  createMerchant: [Types.ClearentCreateMerchantProp, Types.ClearentCreateMerchantStatusProp]
  updateMerchant: [Types.ClearentUpdateMerchantProp, Types.ClearentUpdateMerchantStatusProp]
  updateAddress: [Types.ClearentUpdateAddressProp, Types.ClearentUpdateAddressStatusProp]
  udpateTaxPayer: [Types.ClearentUpdateTaxPayerProp, Types.ClearentUpdateTaxPayerStatusProp],
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const ClearentMerchant = ({ merchantSetting,
  createMerchant: [createMerchant],
  updateMerchant: [updateMerchant],
  dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')

  //const [] = useState(false)

  let initialValues = {
    merchantNumber: merchantSetting.clearentMerchantSetting.merchantNumber ?? '',
    defaultName: merchantSetting.clearentMerchantSetting.defaultName ?? '',
    defaultEmail: merchantSetting.clearentMerchantSetting.defaultEmail ?? '',
    connected: merchantSetting.clearentMerchantSetting.connected,
    phoneNumber: '',
    phoneArea: ''
  }

  const merchant = merchantSetting.clearentMerchantSetting.merchantAccount;
  
  if(merchant){
    const phone = merchant.phones?.find(x => x.phoneTypeCodeID === 1);
    initialValues.defaultName = merchant.dbaName ?? '';
    initialValues.defaultEmail = merchant.emailAddress ?? '';
    initialValues.phoneArea = phone?.areaCode ?? '';
    initialValues.phoneNumber = phone?.phoneNumber ?? '';
  }
  

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={args => {
          if (!dirty && args !== initialValues) setDirty(true)
        }}
        onSubmit={args => {

          showConfirmDialog({
            body: 'Are you sure you want to create new merchant?',
            confirm: () => {
              if(initialValues.merchantNumber){
                updateMerchant({
                  variables: {
                    dbaName: args.defaultName,
                    email: args.defaultEmail,
                    phone_area: args.phoneArea,
                    phone_number: args.phoneNumber,
                  }
                })
              }else{
                createMerchant({
                  variables: {
                    dbaName: args.defaultName,
                    email: args.defaultEmail,
                    phone_area: args.phoneArea,
                    phone_number: args.phoneNumber,
                  }
                })
              }
            }
          })
        }}
        validationSchema={Yup.object({
          defaultName: Yup.string().required('Business Name is required'),
          defaultEmail: Yup.string().required('Email is required'),
          phoneNumber: Yup.string().required('Phone Number is required'),
          phoneArea: Yup.string().required('Phone Area is required'),
        })}
      >
        {() => (
          <Form style={{ height: '100%', width: '100%' }}>
            <SheetBodyLayout>
              <Pane gridArea="body" background="blueTint" overflow="scroll">
                <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                  <CardHeader>
                    <Heading size={500}>Business Details</Heading>
                  </CardHeader>
                  <Pane display={initialValues.connected ? 'flex' : 'none'} marginTop={8} marginX={12} >
                    <TextInputField name="merchantNumber" disabled={true} label="Merchant Number" placeholder="" height={40} width="100%"  />
                  </Pane>
                  <Pane display="flex" marginTop={0} marginX={12} >
                    <TextInputField name="defaultName" label="Business Name" placeholder="" height={40} width="100%"  />
                  </Pane>
                  <Pane display="flex" marginTop={0} marginX={12} >
                    <TextInputField name="defaultEmail" label="Email ID" placeholder="" height={40} width="100%"  />
                  </Pane>
                  <Pane display="flex" marginTop={0} marginX={12} >
                    <Pane flex={1}>
                      <Label marginLeft={0} marginBottom={4} alignSelf="flex-start" display="block">
                        Primary Phone
                    </Label>
                      <Pane display="flex">
                        <TextInput
                          marginLeft={0}
                          type="text"
                          name="phoneArea"
                          placeholder="555" height={40} width={70} marginRight={10}
                        />
                        <TextInput
                          type="text"
                          name="phoneNumber"
                          placeholder="" height={40} width="100%"
                        />
                      </Pane>
                    </Pane>
                  </Pane>
                  <FormError marginLeft={0} marginTop={0} max={2} />
                  <Pane display="flex" marginTop={0} marginBottom={20} marginX={12} >
                    <Button
                      type="submit"
                      justifyContent="center"
                      appearance="primary"
                      marginRight={8}
                      marginLeft={0}
                    >
                      Create Merchant
                    </Button>
                    <Button
                      type="button"
                      justifyContent="center"
                    >
                      Cancel
                    </Button>
                  </Pane>
                </Card>

              </Pane>
            </SheetBodyLayout>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ClearentMerchant

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
