import * as GraphqlTypes from './graphql'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MutationFunctionOptions, ExecutionResult, MutationResult } from '@apollo/react-common'

export * from './graphql'

export interface Plan {
  id: string
  name: string
  annualPriceActive: boolean
  annualPrice: number | null
  monthlyPriceActive: boolean
  monthlyPrice: number | null
  status: GraphqlTypes.PlanStatus
  allTimeMemberCount: number
  stripe?: {
    createdAt: number
  } | null
  isDefault: boolean
}

export type PrimaryLink = {
  name: string
  path: string
  icon: IconProp
  headerLinks: {
    name: string
    path: string
    component: React.FC<any>
    fullWidth?: boolean
  }[]
}

export type EnrollmentState = {
  start?: {
    email: string
  }
  details?: {
    firstName: string
    lastName: string
    phone: string
    dobMonth: number
    dobDay: number
    dobYear: number
    perio: boolean
  }
  plan?: {
    id: string
    name: string
    term: PlanTerm
    price: number
    dependentDiscount: number | null
  }
}

export enum PlanTerm {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY'
}

export type UpdatePlanProp = (
  options?: MutationFunctionOptions<GraphqlTypes.UpdatePlan, GraphqlTypes.UpdatePlanVariables> | undefined
) => Promise<ExecutionResult<GraphqlTypes.UpdatePlan>>
export type UpdatePlanStatusProp = MutationResult<GraphqlTypes.UpdatePlan>

export type ClearentCreateMerchantProp = (
  options?: MutationFunctionOptions<GraphqlTypes.clearentCreateMerchant, GraphqlTypes.clearentCreateMerchantVariables> | undefined
) => Promise<ExecutionResult<GraphqlTypes.clearentCreateMerchant>>
export type ClearentCreateMerchantStatusProp = MutationResult<GraphqlTypes.clearentCreateMerchant>

export type ClearentUpdateMerchantProp = (
  options?: MutationFunctionOptions<GraphqlTypes.clearentUpdateMerchant, GraphqlTypes.clearentUpdateMerchantVariables> | undefined
) => Promise<ExecutionResult<GraphqlTypes.clearentUpdateMerchant>>
export type ClearentUpdateMerchantStatusProp = MutationResult<GraphqlTypes.clearentUpdateMerchant>


export type ClearentUpdateAddressProp = (
  options?: MutationFunctionOptions<GraphqlTypes.clearentUpdateAddress, GraphqlTypes.clearentUpdateAddressVariables> | undefined
) => Promise<ExecutionResult<GraphqlTypes.clearentUpdateAddress>>
export type ClearentUpdateAddressStatusProp = MutationResult<GraphqlTypes.clearentUpdateAddress>


export type ClearentUpdateTaxPayerProp = (
  options?: MutationFunctionOptions<GraphqlTypes.clearentUpdateTaxPayer, GraphqlTypes.clearentUpdateTaxPayerVariables> | undefined
) => Promise<ExecutionResult<GraphqlTypes.clearentUpdateTaxPayer>>
export type ClearentUpdateTaxPayerStatusProp = MutationResult<GraphqlTypes.clearentUpdateTaxPayer>


// IF CHANGED, CHANGE IN MEMBER/LIB TYPES
export type SecureContact = {
  email?: string
  firstName: string
  lastName: string
  phone?: string
  dobMonth: number
  dobDay: number
  dobYear: number
  perio: boolean

  address1?: string
  address2?: string
  city?: string
  state?: GraphqlTypes.State
  zip?: string
}

export type SecureContactNotes = {
  createdAt: number
  body: string
}[]

export type Practice = {
  practiceName: string
  plan: string
  status: string
  lastPayment: string
  renewalDate: string
  accountId: string
  accountStatus: string
}

export enum OnboardingStep {
  START = 'START',
  PRACTICE = 'PRACTICE',
  OFFICES = 'OFFICES',
  DENTISTS = 'DENTISTS',
  BANK = 'BANK',
  SUMMARY = 'SUMMARY',
  COMPLETE = 'COMPLETE'
}

export interface Video {
  id: string
  index: number
  title: string
  url: string
  status: string
}

export interface Flyer {
  id: string
  index: number
  title: string
  url: string
  status: string
}