import React, {  useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
//import { UPSERT_OFFICE, DELETE_OFFICE, GET_OFFICES } from 'graphql/_office-sheet'
import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Heading, TextInputField, Label } from 'evergreen-ui'
import { Card, CardHeader, Button, FormError, DoubleTextInput, InfoTooltip, SegmentedControl, TextInput } from '@pearly/lib'

import { useModal } from 'components/modal-provider'
import { CLEARENT_ADD_BUSINESSCONTACT } from 'graphql/_clearent-onboarding'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  owner?: {
    businessContactId: number | null;
    emailAddress: string | null;
    ownershipAmount: number;
    title: string;
  }

}

const OwnerSheet = ({ isShown, setIsShown, owner }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [ownerState, setOwnerState] = useState(1)
  const [addContact] = useMutation<Types.clearentAddBusinessContact, Types.clearentAddBusinessContactVariables>(CLEARENT_ADD_BUSINESSCONTACT);


  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          owner
            ? { 
              businessContactId: '',
              EmailAddress: '',
              OwnershipAmount: '',
              Title: '',
              FirstName: '',
              LastName: '',
              Phone: '',
              SSN: '',
              AddressLine1: '',
              AddressLine2: '',
              City: '',
              State: '',
              ZipCode: ''
           }
            : {
              businessContactId: '',
              EmailAddress: '',
              OwnershipAmount: '',
              Title: '',
              FirstName: '',
              LastName: '',
              Phone: '',
              SSN: '',
              AddressLine1: '',
              AddressLine2: '',
              City: '',
              State: '',
              ZipCode: ''
            }
        }
        onSubmit={args => {
          showConfirmDialog({
            body: `Are you sure you want to ${owner ? 'update' : 'create'} this owner?`,
            confirm: () => {
              //TODO Add or Update
              addContact({
                variables:{
                  title: args.Title,
                  ownershipAmount: Number(args.OwnershipAmount),
                  emailAddress: args.EmailAddress,
                  contact: {
                    countryOfCitizenshipCode: 1,
                    dateOfBirth:'',
                    firstName: args.FirstName,
                    lastName: args.LastName,
                    ssn: args.SSN,
                    

                  }
                }
              })
            }
          })
        }}
        validationSchema={Yup.object({

        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {owner ? (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>{owner.title}</Heading>
                <Paragraph size={400}>Owner Details</Paragraph>
              </CardHeader>
            ) : (
                <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                  <Heading size={600}>Add Owner</Heading>
                  <Paragraph size={400}>This owner will be added to your list</Paragraph>
                </CardHeader>
              )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={1} >
                <Pane display="flex" marginTop={8} marginX={12} >
                  <TextInputField name="Title" label="Title" placeholder="" height={40} width="100%" />
                </Pane>

                <Pane marginTop={0} marginX={12}>
                  <Label marginBottom={8} alignSelf="flex-start" display="block">
                    Contact Type
                        <InfoTooltip
                      position="top"
                      content="Select Type of Ownership"
                    />
                  </Label>
                  <SegmentedControl
                    value={ownerState}
                    onChange={(value: React.SetStateAction<Number>) => setOwnerState(Number(value))}
                    options={[
                      { label: 'Owner', value: 1 },
                      { label: 'Signer', value: 2 },
                    ]}
                  />
                </Pane>
                <Pane marginTop={0} marginX={12} >
                  <Label marginBottom={8} alignSelf="flex-start">
                    {ownerState === 2 ? 'Signer' : 'Owner'} Information
                        </Label>
                  <DoubleTextInput name="FirstName" half="top" width="100%" type="text" placeholder="First Name" paddingLeft={20} />
                  <DoubleTextInput name="LastName" half="bottom" width="100%" type="text" placeholder="Last Name" />

                  <TextInput display={ownerState === 2 ? 'block' : 'none'} name="Email" placeholder="Email" height={40} width="100%" />
                  <TextInput name="Phone" placeholder="Phone" height={40} width="100%" />
                  <TextInput name="SSN" placeholder="SSN" height={40} width="100%" />
                </Pane>
                <Pane marginTop={0} marginX={12} >
                  <Label marginBottom={8} alignSelf="flex-start">
                    Address
                        </Label>
                  <DoubleTextInput name="AddressLine1" half="top" width="100%" type="text" placeholder="AddressLine1" paddingLeft={20} />
                  <DoubleTextInput name="AddressLine2" half="bottom" width="100%" type="text" placeholder="AddressLine2" />

                  <TextInput name="City" placeholder="City" height={40} width="100%" />
                </Pane>

                <Pane display="flex" justifyContent="space-between" marginTop={0} marginX={12} >
                  <TextInput name="State" type="text" placeholder="CA" marginRight={8} width="100%" />
                  <TextInput name="ZipCode" type="text" placeholder="93101" marginLeft={8} width="100%" />
                </Pane>


                <FormError marginLeft={20} marginTop={0} max={2} />

              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {owner ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete?',
                        confirm: () => {
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                  <Button
                    type="submit"
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    iconBefore={['fas', 'plus']}
                  >
                    Add {ownerState === 2 ? 'Signer' : 'Owner'}
                  </Button>
                )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default OwnerSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
